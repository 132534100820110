// @General Styling
// @Dropdown
a.dropdown-toggle {
	cursor: pointer !important;
}
.dropdown-toggle:after {
	content: "";
	width: 0.5rem;
	height: 0.5rem;
	display: inline-block;
	border-left: 1px solid $gray-500;
	border-bottom: 1px solid $gray-500;
	border-top: none;
	border-right: none;
	transform: rotate(-45deg);
	padding-top: 0.25rem;
	margin-left: 0.4rem;
	display: none;
}
.dropdown-menu {
	&.show {
		right: 0px;
		button {
			cursor: pointer;
		}
	}
	box-shadow: $box-shadow;
}

// @V3
.dropdown-menu.dropdown-menu-dark {
	min-width: 16rem;
	background-color: $navy-dark;
	.dropdown-item,
	.dropdown-item-text {
		font-size: 14px;
		color: $white;
		background-color: $navy-dark;
		.dropdown-text {
			border-bottom: 2px solid transparent;
			color: $white;
			display: inline-flex;
			padding: 0.25rem 0;
		}
		&:hover,
		&.active {
			.dropdown-text {
				border-bottom: 2px solid $white;
			}
		}
	}
}

ngb-typeahead-window {
	&.dropdown-menu {
		min-width: 18rem;
		max-height: 300px;
		overflow: scroll;
		// overflow: hidden;
		// height: auto;
		background-color: $navy-dark;
		.dropdown-item {
			color: $white;
			.result-item {
				margin: 0.125rem 0;
			}
		}
	}
}
