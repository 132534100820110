.onboarding-content {
	min-height: calc(100vh - 68px);
	background-color: transparent;

	display: flex;
	align-items: center;
	justify-content: center;

	router-outlet {
		display: none;
	}

	.onboarding-steps {
		width: 660px;
		max-width: 100%;

		text-align: center;

		color: #fff;

		.btn {
			padding-left: 1.5rem;
			padding-right: 1.5rem;
		}

		label {
			color: #fff;
		}

		.form-group {
			margin-bottom: 1.5rem;
		}

		button {
			// margin: 1.5rem auto;
			// display: inline-block;
		}
	}
}
