.input_poly {
	&.input_poly__checkbox {
		display: flex;
		flex-direction: column;
		flex-wrap: nowrap;
		justify-content: space-between;
		align-items: center;
		align-self: stretch;
		.checkbox__label {
			font-size: .8rem;
			width: 5rem;
			text-align: center;
			display: block;
			flex: 0 0 1;
			align-self: baseline;
		}
		.checkbox__button {
			transition: all .15s;
			flex: 0 0 1;
			align-self: center;
			margin-top: .25rem;
			width: 3rem;
			height: 3rem;
			border-radius: 100%;
			border: 2px solid map-get($app-greys-map, 4);
			background-color: transparent;
			display: flex;
			justify-content: center;
			align-items:center;
			padding: 0;
			&:after {
				transition: all .15s;
				flex: 0 0 1;
				width: 2rem;
				height: 2rem;
				background-color: transparent;
				border-radius: 1rem;
				display: block;
				content: "";
				z-index: 2;
				position: relative;
			}
			&:hover,
			&:focus,
			&:active,
			&.active {
				cursor: pointer;
				outline: none !important;
				@each $color in $app-colors {
					&.color-#{ nth($color, 1)} {
						border-color: nth($color, 2);
						&:after {
							background-color: nth($color, 2);
						}
					}
				}
			}
		}
	}
}