$font-size-root: 16px;

@function strip-unit($number) {
    @if type-of($number) == 'number' and not unitless($number) {
        @return $number / ($number * 0 + 1);
    }
    @return $number;
}

@function rem-val($number) {
    $base: strip-unit($font-size-root);
    $val: $number / $base;
    $return: $val * 1rem;
    @return $return;
}

@function em-val($number) {
    $base: strip-unit($font-size-root);
    $val: $number / $base;
    $return: $val * 1em;
    @return $return;
}

// @Placeholder
@mixin placeholder() {
    &::-webkit-input-placeholder {
        @content;
    }
    &:-moz-placeholder {
        @content;
    }
    &::-moz-placeholder {
        @content;
    }
    &:-ms-input-placeholder {
        @content;
    }
}

/// Position Shorthand
@mixin position($pos,$t,$r,$b,$l) {
	position: $pos;
	top: $t;
	right: $r;
	bottom: $b;
	left: $l;
}

/// On hover, focus, acticve shorthand
@mixin on-action() {
	&:hover,
	&:active,
	&:focus {
		outline: none;
		@content;
	}
}

/// Gradient Generation Mixin
/// @group _gradient
@mixin gradient-v2($start,$end,$deg: 135deg) {
    background: $start;
    background: -moz-linear-gradient($deg, $start 0%, $end 100%); // FF
    background: -webkit-linear-gradient($deg, $start 0%, $end 100%); // Chrome10-25,Safari5.1-6
    background: linear-gradient($deg, $start 0%, $end 100%); // W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#{$start}', endColorstr='#{$end}',GradientType=1 ); // IE6-9 fallback on horizontal gradient
}

// Full Gradient Vertical or Horizontal
// ( rgb/hex, rgb/hex, bool --> true for vertical/false for horizontal )
@mixin gradient( $start, $stop, $direction:false ) {
	@if $direction == true {
		background: $start;
		background: -moz-linear-gradient(top, $start 0%, $stop 100%);
		background: -webkit-gradient(left top, left bottom, color-stop(0%, $start), color-stop(100%, $stop));
		background: -webkit-linear-gradient(top, $start 0%, $stop 100%);
		background: -o-linear-gradient(top, $start 0%, $stop 100%);
		background: -ms-linear-gradient(top, $start 0%, $stop 100%);
		background: linear-gradient(to bottom, $start 0%, $stop 100%);
		filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#{$start}', endColorstr='#{$stop}', GradientType=0 );
	}
	@if $direction == false {
		background: $start;
		background: -moz-linear-gradient(left, $start 0%, $stop 100%);
		background: -webkit-gradient(left top, right top, color-stop(0%, $start), color-stop(100%, $stop));
		background: -webkit-linear-gradient(left, $start 0%, $stop 100%);
		background: -o-linear-gradient(left, $start 0%, $stop 100%);
		background: -ms-linear-gradient(left, $start 0%, $stop 100%);
		background: linear-gradient(to right, $start 0%, $stop 100%);
		filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#{$start}', endColorstr='#{$stop}', GradientType=1 );
	}
}