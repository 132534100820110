// Assessment Colors - concatenate with any addition (name, hex) pairs per theme
/*$app-colors:
	(sa, #FFB03D),  //strongly agree
	(a, #FF9C4E), 	//agree
	(n, #FF895F), 	//neither
	(d, #EF665D), 	//disagree
	(sd, #D33153);  //strongly disagree
 */ /*
$app-colors-map: (
	sa: #FFB03D,    //strongly agree
	a: #FF9C4E, 	//agree
	n: #FF895F, 	//neither
	d: #EF665D, 	//disagree
	sd: #D33153     //strongly disagree
);
*/
$app-colors: (sa, #30dbff), (a, #30cbff), (n, #31abff), (d, #308dff),
	(sd, #307eff);

$app-colors-map: (
	sa: #30dbff,
	a: #30cbff,
	n: #31abff,
	d: #308dff,
	sd: #307eff
);

//
// Assessment Color Override
// @author LWK
//
$teal: #30dbff;
$purple: #6137ff;
$app-colors: (sa, mix($teal, $purple, 0%)), (a, mix($teal, $purple, 25%)),
	(n, mix($teal, $purple, 50%)), (d, mix($teal, $purple, 75%)),
	(sd, mix($teal, $purple, 100%));
$app-colors-map: (
	sa: mix($teal, $purple, 0%),
	a: mix($teal, $purple, 25%),
	n: mix($teal, $purple, 50%),
	d: mix($teal, $purple, 75%),
	sd: mix($teal, $purple, 100%)
);

// Assessment Greys
$app-greys: (1, #f6f6f6), (2, #9aa1ab), (3, #717a87), (4, #6f6f6f), (5, #424242);

$app-greys-map: (
	1: #f6f6f6,
	2: #9aa1ab,
	3: #717a87,
	4: #6f6f6f,
	5: #424242
);

@function app-color($color: "sa") {
	@return map-get($app-colors-map, $color);
}

//
// Application Color Setup
//

//Generate color classes
@each $c in $app-colors {
	$color: nth($c, 2);
	//Semantic
	.font-color-#{nth($c,1)} {
		color: $color;
	}
	.bg-color-#{nth($c,1)} {
		background-color: $color;
	}
	.border-color-#{nth($c,1)} {
		border-color: $color;
	}
}

//Generate Grey Classes
@each $c in $app-greys {
	$color: nth($c, 2);
	//Semantic
	.font-grey-#{nth($c,1)} {
		color: $color;
	}
	.bg-grey-#{nth($c,1)} {
		background-color: $color;
	}
	.border-grey-#{nth($c,1)} {
		border-color: $color;
	}
}
