/// @Content - General styling

.text-heading {
	color: $gray-400;
	text-transform: uppercase;
	letter-spacing: 1px;
	font-size: 80%;
}

b {
	font-weight: $font-weight-medium;
}
