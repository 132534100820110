// @group Form
.custom-control {
	// styling for block display radio buttons
	&.custom-radio {
		&.custom-radio--button {
			border: 1px solid $gray-400;
			border-radius: $border-radius;
			padding: 0.375rem 0.375rem 0.375rem 3.5rem;

			label {
				width: 100%;
			}

			@include on-action() {
				border-color: $primary;
			}

			& + .custom-radio {
				margin-top: 0.75rem;
			}

			// @sizing
			&.form-control-lg {
				padding-top: 0.5rem;
				padding-bottom: 0.5rem;

				.custom-control-label {
					&:before,
					&:after {
						width: 1.2rem;
						height: 1.2rem;
						overflow: visible;
						top: 0.3rem;
					}
				}
			}
		}
	}
}
.custom-file {
	.custom-file-label {
		overflow: hidden;
		white-space: nowrap;
	}
}

.form-group {
	&.form-group--inset {
		position: relative;
		.form-group-button {
			position: absolute;
			right: 0.25rem;
			top: 50%;
			transform: translateY(-50%);
			padding: 0.15rem 0.25rem;
			font-size: 0.75rem;
		}
	}
}
