// App Layout Styles
// @see directives/layout.directive
.layout-container {
	&.layout-container--sidebars,
	&.layout-container--sidebars-full,
	&.layout-container--sidebars-scroll {
		height: calc(100vh - #{rem-val(68)});
		overflow: scroll;

		aside {
			@include media-breakpoint-up(md) {
				position: sticky;
				top: 0;

				height: auto;
				align-self: flex-start;
			}
		}
	}

	&.layout-container--sidebars-scroll {
		height: auto;
		overflow: inherit;

		aside {
			top: 68px;
		}
	}

	&.layout-container--sidebar-full {
		position: relative;

		.dialog-overlay {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;

			padding: 2rem 1rem;

			background: rgba($primary, 0.2);
			color: $primary;
		}

		@include media-breakpoint-up(md) {
			aside,
			main,
			.sidebar,
			.main {
				min-height: calc(100vh - 68px);
			}
		}
	}

	&.layout-container--sidebars-full {
		height: auto;
		overflow: initial;

		&.container-fluid {
			padding-left: 2rem;
			padding-right: 2rem;

			.row {
				justify-content: space-between;
			}

			@include media-breakpoint-up(lg) {
				aside {
					top: 68px;
					height: calc(100vh - 68px);
					overflow: scroll;
				}
				.col-md-3 {
					flex: 0 0 auto;
					width: 332px;
					// max-width: 332px;
				}
				.col-md-6 {
					flex: 0 0 auto;
					width: 700px;
					// max-width: 700px;
				}
				main {
					height: auto;
				}
			}

			@include media-breakpoint-down(md) {
				padding-left: $spacer;
				padding-right: $spacer;
				aside {
					display: none;
				}
			}
		}
	}

	&.layour-container--slim {
		@include media-breakpoint-up(lg) {
			.container {
				max-width: 1040px !important;
			}
		}
	}

	&.layout-container--skinny {
		@include media-breakpoint-up(md) {
			.container {
				max-width: 800px !important;
			}
		}
	}
}

// Insights Overflow
// .insights-wrapper {
// 	display: flex;
// 	flex-wrap: nowrap;
// 	width: calc(100% + 30px);
// 	margin: 0 -15px;
// 	padding-left: 15px;
// 	overflow: scroll;
// 	> * {
// 		$size: calc(100% - 30px);
// 		flex: 0 0 $size;
// 		margin-right: 0.5rem;
// 	}
// }
