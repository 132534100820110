// @group button
.btn {
	@each $color, $hex in $theme-colors {
		&.btn-clear-#{$color} {
			color: $hex;
			@include on-action() {
				color: lighten($hex, 10%);
			}
		}
	}

	&.btn-light {
		background: #faf9fa;
		border-color: #dad8de;
		color: #646067;
	}

	&--fab {
		z-index: 1039; // 1 below modal backdrop
		&-feedback {
			position: fixed;
			left: 1.5rem;
			bottom: 1.5rem;
			@include media-breakpoint-down(sm) {
				left: 0.5rem;
				bottom: 0.5rem;
				width: 2.5rem !important;
				height: 2.5rem !important;
			}
		}
	}

	&-rounded {
		border-radius: 1.5rem;
		font-weight: normal;
		font-size: 14px;
		margin: 0 0.125rem 0.25rem;
	}

	// @v3 rounded
	font-size: 14px;
	font-weight: bold;
	border-radius: 16px;
	// border: none !important;
	&:not(.p-1):not(.p-2):not(.p-3) {
		padding: 0.25rem 1rem 0.3rem !important;
	}
}

// @close button
// Navbar
.btn-close,
.navbar-slideout-close {
	border: none;
	padding: 0;
	background-color: transparent;

	text-align: left;

	.btn-close-icon,
	.navbar-close-icon {
		$close-icon-size: 2.75rem;
		display: inline-block;
		position: relative;
		width: $close-icon-size;
		height: $close-icon-size;
		&:before,
		&:after {
			position: absolute;
			content: "";
			left: 50%;
			top: 0;
			height: $close-icon-size;
			border-left: 1px solid $gray-500;
		}

		&:before {
			transform: rotate(45deg);
		}
		&:after {
			transform: rotate(-45deg);
		}
	}

	.navbar-close-icon {
		&:before,
		&:after {
			border-left-color: #fff;
		}
	}
}
