// @import // Fonts
// 	url("https://fonts.googleapis.com/css?family=Roboto:100,300,400,600,700,900");
@import // Bootstrap Dependencies
	"node_modules/bootstrap/scss/functions",
	"node_modules/bootstrap/scss/mixins";
@import // Bootstrap Customization & Variables
	"scss/colors",
	"scss/variables", "scss/pastels", "scss/mixins", "scss/input";
@import // Bootstrap
	"node_modules/bootstrap/scss/bootstrap";
@import // Global
	"scss/utility",
	"scss/report", "scss/layout", "scss/loading", "scss/feedback", "scss/chart",
	"scss/onboarding", "scss/heading";
@import // Bootstrap Components
	"scss/alert",
	"scss/button", "scss/card", "scss/content", "scss/forms", "scss/dropdown",
	"scss/nav", "scss/popover", "scss/modal";
@import // Glide
	"node_modules/@glidejs/glide/src/assets/sass/glide.core";

// Print Styles
.route--me-report-download {
	app-navigation,
	app-feedback-fab {
		display: none;
	}
}
@media print {
	@import "scss/print";
}

// Navigation Show/Hide
[class*="route--auth"] {
	app-navigation {
		display: none !important;
	}
}

// Accessibility
@media (prefers-reduced-motion: reduce) {
	*,
	::before,
	::after {
		animation-duration: 0.001s !important;
		animation-iteration-count: 1 !important;
		transition-duration: 0.001s !important;
		animation-delay: 0s !important;
	}
}
