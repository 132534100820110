.card {
	// box-shadow: $box-shadow;
	margin-bottom: 0.5rem;

	&.card-transparent {
		background-color: transparent;
		box-shadow: none;
		border: none;
	}

	&-header,
	&-footer {
		background-color: $gray;
	}

	&-header {
		.card-title,
		> strong {
			font-size: 20px;
			font-weight: 900;
		}
	}
}

app-user-top-five {
	display: block;
	& + app-user-top-five {
		margin-top: -0.5rem;
	}
}

// User Compare Cards
.user-compare-cards {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;

	padding: 1.25rem 1.25rem 0;

	@include media-breakpoint-up(md) {
		app-user-compare-card {
			display: flex;
			align-self: stretch;
			flex: 0 0 calc(50% - 0.625rem);
			margin-bottom: 1.25rem;
		}
	}
}
.comparison-header {
	padding: 1rem 1.25rem 0;

	.comparison-header-text {
		margin-bottom: 0;

		.provider-name {
			font-weight: bolder;
		}
		.text {
		}
		.user-name {
			font-weight: bolder;
		}
	}
}
