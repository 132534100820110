// @group nav
.nav {
	.nav-link {
		&.nav-link-child {
			font-size: 0.8rem;
			width: calc(100% - 1rem);
			margin-left: auto;
		}
	}

	// @Display support
	.nav-children-active-indicator {
		& + .nav-children {
			display: none;
		}
		&.active {
			& + .nav-children {
				display: block;
			}
		}
	}

	&.nav-pills {
		.nav-link {
			font-weight: 900;
			color: $body-color;
			border-radius: 20px;
			padding: 0.35rem 0.75rem;
			margin-right: 0.25rem;
			&.active,
			&:hover {
				background-color: $gray-200;
				color: $primary;
			}
		}
	}

	&.nav-tabs {
		border-bottom-width: 2px;

		.nav-item {
			.nav-link {
				color: $primary;
				text-transform: uppercase;

				border-color: transparent;
				border-left: none;
				border-right: none;

				border-bottom: 2px solid transparent !important;

				padding: 0.75rem 1rem;

				@include on-action() {
					background: transparent;
					border-bottom: 2px solid $primary !important;
				}

				&.active {
					background: transparent;
					border-color: transparent;
					border-bottom: 2px solid $primary !important;
				}
			}
		}
		& + .tab-content {
			.tab-pane {
				// padding: 0.75rem 1.25rem;
			}
		}
	}

	// Underlined
	&.nav-underline {
		.nav-link {
			color: $navy-dark;
			padding: 0.5rem 0;
			border-bottom: 2px solid transparent;
			& + .nav-link {
				margin-left: 2rem;
			}
			&.active,
			&:hover {
				color: $navy-dark;
				border-bottom: 2px solid $navy-dark;
			}
		}
	}
}
