.asmnt-rpt-content {
	.content__panel {
		&.panel__traits {
			background-color: transparent;

			display: flex;
			flex-wrap: nowrap;

			// > div {
			//     padding: 2rem;
			// }

			.panel__left {
				flex: 0 0 25%;
				width: 25%;
			}
			.panel__right {
				flex: 0 0 75%;
				width: 75%;
				padding: 3rem 3rem 3rem 2rem;
			}

			.trait__segment--container {
				height: calc(100vh - 68px);

				overflow-y: scroll;

				position: sticky;
				top: 68px;

				background-color: #fff;

				// .trait__list {
				//     height: 100%;
				//     display: flex;
				//     flex-direction: column;
				//     justify-content: space-around;
				//     .trait__list--item {
				//         flex: 0 1 0;
				//     }
				//     .trait__list--link {
				//         padding-top: 0;
				//         padding-bottom: 0;
				//         margin-top: 0;
				//         margin-bottom: 0;
				//     }
				// }
			}

			.trait__content--container {
				.trait__panel {
					margin: 10vh 0;

					position: relative;

					.trait__icon {
						position: absolute;

						left: 5rem;
						right: 5rem;
						bottom: 5rem;
						top: rem-val(100);

						z-index: -1;

						svg {
							height: 100%;
							width: 100%;
							opacity: 0.05;
						}
					}

					.trait__panel--heading {
						position: sticky;
						top: rem-val(68);
						background-color: #f6f6f6; // #EBEDF0;
						display: block;
						z-index: 3;
						h2 {
							font-size: rem-val(64);
							font-weight: 200;
							margin: 1rem 0;
						}
						.trait-meta {
							opacity: 0.75;
							font-style: italic;
						}
						& + div {
							margin-top: 4rem;
						}
					}

					.trait__panel--meta {
						margin-bottom: 3rem;

						.meta-title {
							display: block;
							text-align: right;
							font-weight: bold;
							text-transform: uppercase;
							font-size: rem-val(12);
							letter-spacing: rem-val(1);
						}

						.meta-content {
							font-size: rem-val(22);
							line-height: rem-val(32);
							font-weight: 200;
						}
					}
				}
			}
		}

		&.panel__actions {
			background-color: #161d29;
			color: #fff;

			.panel__actions--heading {
				h2 {
					font-size: rem-val(64);
					font-weight: 200;
					text-align: center;
				}
				padding: 3rem 0;
			}
		}
	}
}

.trait__list {
	list-style-type: none;
	padding-left: 0;

	.trait__list--item {
		padding: 0;
		.trait__list--link {
			padding: 0.5rem 2rem;
			margin: 1rem 0;

			display: flex;
			align-items: center;

			color: inherit;

			transition: all 0.3s;
			transform-origin: left center;

			cursor: pointer;

			&:hover,
			&.active {
				font-weight: bold;
				transform: scale(1.15);
			}

			@include on-action() {
				text-decoration: none;
			}

			.trait-icon {
				display: inline-block;
				width: 1.25rem;
				height: 1.25rem;
				line-height: 0;
			}
			.trait-name {
				margin-left: 2rem;
			}
		}
	}
}

.trait {
	text-align: center;

	.trait-icon-wrapper {
		width: rem-val(160);
		height: rem-val(160);
		border-radius: 50%;

		margin: 0 auto;

		background-color: #fff;
		box-shadow: 0 9px 14px rgba(#000, 0.08);

		display: flex;
		align-items: center;
		justify-content: center;

		.trait-icon-svg {
			$size: 54;
			height: rem-val($size);
			width: rem-val($size);
		}
	}

	.trait-name-wrapper {
		margin-top: 1.5rem;

		font-size: rem-val(20);
		font-weight: 200;
	}
}
