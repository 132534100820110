// @Chart Styles

// @User Trait Comparison Bar Chart
app-user-result-comparison-bar-chart {
    svg {
        // overflow: visible;

        text {
            font-family: $font-family-sans-serif !important;
        }
        .bar {
            opacity: .75;
            &.user-0 {
                fill: $gray-400;
            }
            &.user-1 {
                fill: $primary;
            }
        }
    
        .bar:hover {
            opacity: 1;
        }

        .legend {
            font: 10px $font-family-sans-serif;
            &.user-0 {
                rect {
                    fill: $gray-400;
                }
            }
            &.user-1 {
                rect {
                    fill: $primary;
                }
            }
        }
    
        .x-axis,
        .y-axis {
            font: 10px $font-family-sans-serif;
            color: $gray-700;
            path {
                display: none;
            }
            .tick {
                line {
                    display: none;
                }
            }
        }
        
        .x-axis.trait-names {
            path {
                display: none;
            }
            text {
                transform: rotate(-70deg);
                text-anchor: end;
            }
        }
    }
}