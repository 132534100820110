/// @group Print
@page {
	size: 8.5in 11in;
	margin: 0;
}

app-navigation,
app-feedback-fab {
	display: none;
}

html,
body {
	width: 100%;
	display: block;

	app-root,
	app-report-export,
	app-report-page {
		display: block;
		width: 100% !important;
		height: auto;
		// height: 100% !important;
	}

	app-report-page {
		width: 100% !important;
		height: 100vh !important;
		// Chrome bugfix -
		// @TODO: this needs to be QC'd
		@media (-webkit-min-device-pixel-ratio: 0) {
			height: 1285px !important;
			width: 100% !important;
		}
	}

	app-root {
		height: auto !important;
		overflow: initial !important;
	}

	padding: 0 !important;
	margin: 0 !important;

	background-color: transparent;
}

blockquote {
	border: none !important;
}
