// @group Popover
.popover {
	border: none;
	border-radius: $border-radius;
	box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);

	&.select-user-popover,
	&.select-trait-popover {
		.popover-body {
			padding: 0;
			min-width: rem-val(200);
		}

		button {
			font-weight: bold;
			@include on-action() {
				background-color: $primary;
				color: #fff;
			}
		}
	}

	&.trait-detail-popover {
		max-width: rem-val(600);
		width: rem-val(600);

		&.trait-detail-popover--hoverable {
			max-width: rem-val(350) !important;
			width: rem-val(350) !important;
		}

		.arrow:before {
			display: none;
		}
		.popover-body {
			padding: 0;
			border-radius: $border-radius;
			overflow: hidden;
		}
	}
}
