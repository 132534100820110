// @Utility classes

// @Filter
.filter-blur {
	filter: blur(5px);
}

// @Overlay
.bg-blend {
	box-shadow: $box-shadow;

	background-size: cover;
	background-position: center center;

	background-color: $navy-dark;
	background-blend-mode: darken, luminosity;
}
