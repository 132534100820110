// @group feedback assets

// @ref feed-item
.feedback-list {
	list-style-type: none;
	padding-left: 0;

	// display: flex;
	// justify-content: space-between;
	// flex-wrap: nowrap;

	.feedback-item {
		// flex-basis: 0;
		// flex-grow: 1;
		// max-width: 100%;

		// align-self: stretch;

		&[disabled] {
			position: relative;
			&:after {
				content: "";
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				display: block;
			}
		}

		button {
			width: 100%;
			height: 100%;
		}

		& + .feedback-item {
			margin-top: 1rem;
		}
	}
}

// Sidebar
aside .user-feedback-intro p.sidebar-content strong {
	font-weight: 900 !important;
}

// @Feedback Grid
.trait-feedback-grid {
	display: flex !important;
	flex-wrap: wrap;

	padding: 0.5rem;

	&.complete {
		.trait-feedback-item:not(.selected) {
			opacity: 0.5;
			// filter: blur(1px);
			&:hover {
				cursor: default;
				app-trait-icon-block {
					box-shadow: none;
				}
			}
		}
	}

	.trait-feedback-item {
		position: relative;

		flex: 0 0 20%;
		width: 20%;

		padding: 0.5rem;

		transition: opacity 0.3s;

		.trait-item-overlay {
			position: absolute;

			width: calc(100% - 1rem);
			height: calc(100% - 1rem);

			top: 0.5rem;
			left: 0.5rem;

			border-radius: 4%;

			background-color: rgba($primary, 0.75);

			display: flex;
			align-items: center;
			justify-content: center;

			span {
				max-width: 80%;
				img {
					width: 100%;
				}
			}

			&.badge {
				left: 1rem;
				top: 1rem;

				width: 16%;
				height: 16%;

				border-radius: 50%;

				background-color: $primary;
			}
		}

		app-trait-icon-block {
			transition: box-shadow 0.3s;
		}

		&:not(.selected) {
			&:hover {
				cursor: pointer;
				app-trait-icon-block {
					box-shadow: 0 0 6px 4px rgba($primary, 0.2);
				}
			}
		}
	}
}

// @Feedback List
.trait-feedback-list {
	margin-top: 2rem;

	list-style-type: none;
	padding-left: 0;

	display: flex;
	flex-wrap: wrap;

	@include media-breakpoint-down(md) {
		flex-direction: column;
		width: 100%;
		.trait-feedback-list-item {
			width: 100%;
		}
	}

	.trait-feedback-list-item {
		@include media-breakpoint-up(md) {
			flex: 0 0 50%;
			width: 50%;
		}
		padding: 0.5rem 0.5rem;

		position: relative;

		action-trait-popover {
			position: absolute;

			right: 0.5rem;
			top: 0;
			bottom: 0;

			display: flex;
			align-items: center;

			.info {
				opacity: 0;
				transition: all 0.3s;
				margin-right: 0.5rem;
			}

			&:hover {
				.info {
					opacity: 1 !important;
				}
			}
		}

		&:hover {
			action-trait-popover {
				.info {
					opacity: 0.5;
				}
			}
		}
	}
}

.trait-select-button {
	width: 100%;

	display: flex;
	justify-content: flex-start;
	align-items: center;

	border-radius: 0px;
	// box-shadow: $box-shadow;
	border-bottom: 2px solid rgba($primary, 0.25);

	.checkbox {
		display: flex;
		justify-content: center;
		align-items: center;

		transition: all 0.2s;

		width: 1.5rem;
		height: 1.5rem;

		padding: 0.25rem;
		margin-right: 0.75rem;

		border-radius: 50%;
		overflow: hidden;

		background-color: rgba($primary, 0.25);

		img {
			transition: all 0.2s;
			width: 100%;
			opacity: 0;
		}
	}

	&:hover,
	&.selected {
		border-bottom-color: $primary;
		.checkbox {
			background-color: $primary;
		}
	}

	&.selected {
		box-shadow: none;
		outline: none;
		.checkbox img {
			opacity: 1;
		}
	}
}

.trait-button {
	background: transparent;
	border-color: $gray-300;
	border-radius: $border-radius;

	padding: 1.5rem 1rem;

	position: relative;

	&:hover {
		.trait-button-icon:before {
			background: $primary;
		}
	}

	&[data-selected="true"] {
		background: $primary !important;

		.trait-button-content {
			.trait-button-title {
				color: #fff !important;
			}
		}

		.trait-button-icon {
			background: transparent;
			border-color: #fff;
		}
		.trait-button-icon:before {
			background: #fff;
		}
	}

	.trait-button-content {
		display: flex;
		align-items: center;
		flex-wrap: nowrap;

		text-align: left;

		.trait-button-icon {
			margin-right: 0.75rem;
		}
	}

	.trait-button-icon {
		display: block;

		position: relative;

		flex: 0 0 20px;

		height: rem-val(20);
		width: rem-val(20);

		border-radius: 50%;

		background: white;
		border: 2px solid $primary;

		&:before {
			content: "";
			display: block;
			position: absolute;
			width: rem-val(12);
			height: rem-val(12);
			left: 50%;
			top: 50%;
			transform: translate(-50%, -50%);
			background: transparent;
			border-radius: 50%;
			transition: background 0.3s;
		}
	}

	.trait-button-title {
		color: $gray-500;
		transition: 0.3s;
	}

	.trait-button-help {
		display: block;
		width: 1.25rem;
		height: 1.25rem;
		border-radius: 50%;
		position: absolute;
		top: 0.5rem;
		right: 0.5rem;
		color: $gray-500;
		border: 2px solid $gray-500;
		font-size: 0.7rem;
		opacity: 0.5;
	}

	&:hover,
	&:focus,
	&:active {
		border-color: $primary;
		.trait-button-title {
			color: $primary;
		}
	}

	&:focus {
		background-color: $primary;
		.trait-button-title {
			color: #fff;
		}
	}
}
