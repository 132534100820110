.modal {
	@include media-breakpoint-down(md) {
		.image-surround.pb-5 {
			padding-bottom: 1.5rem !important;

			sas-logo {
				svg {
					height: 42px !important;
				}
			}
		}
	}

	// Invite Modal
	.close-button {
		position: absolute !important;
		top: 1.5rem;
		right: 1.5rem;
		z-index: 2;
	}
}
